import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatDialogModule } from '@angular/material';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { GalleryComponent } from './home/gallery/gallery.component';
import { InformationComponent } from './home/information/information.component';
import { ExplanationComponent } from './home/explanation/explanation.component';
import { AgreementComponent } from './about/agreement/agreement.component';
import { PrivacyComponent } from './about/privacy/privacy.component';
import { ConditionsComponent } from './about/conditions/conditions.component';
import { ComplaintsComponent } from './about/complaints/complaints.component';
import { AvgComponent } from './about/avg/avg.component';
import { PricesComponent } from './about/prices/prices.component';

@NgModule({
  entryComponents: [
    HomeComponent,
    ExplanationComponent,
    GalleryComponent,
    InformationComponent,
    AgreementComponent,
    ConditionsComponent,
    PricesComponent,
    PrivacyComponent,
    AvgComponent,
    ComplaintsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    NgbModule,
  ],
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    FooterComponent,
    ExplanationComponent,
    GalleryComponent,
    InformationComponent,
    AgreementComponent,
    PrivacyComponent,
    ConditionsComponent,
    ComplaintsComponent,
    AvgComponent,
    PricesComponent,
  ],
  providers: [],
  bootstrap: [AppComponent, HomeComponent],
})
export class AppModule { }
