import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { GalleryComponent } from './gallery/gallery.component';
import { InformationComponent } from './information/information.component';
import { ExplanationComponent } from './explanation/explanation.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openExplanation() {
    let dialogRef = this.dialog.open(ExplanationComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
 
  openGallery() {
    let dialogRef = this.dialog.open(GalleryComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openInformation() {
    let dialogRef = this.dialog.open(InformationComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  reservation() {
    alert('Het reserveringssysteem wordt nog gebouwd. U kunt een afspraak maken via telefoon of e-mail. Kijk hiervoor op mijn contactpagina.')
  }

}