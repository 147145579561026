import { Component } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
  providers: [NgbCarouselConfig]
})
export class GalleryComponent {

  images = [
    '../assets/images/gallery/01_binnenkomst.jpg',
    '../assets/images/gallery/02_diagnose.jpg',
    '../assets/images/gallery/03_plaatsnemen.jpg',
    '../assets/images/gallery/04_naalden.jpg',
    '../assets/images/gallery/05_ooracupunctuur.jpg',
    '../assets/images/gallery/06_stroom.jpg',
    '../assets/images/gallery/07_cupping.jpg',
    '../assets/images/gallery/08_moxibustion.jpg',
    '../assets/images/gallery/09_FSN.jpg',
    '../assets/images/gallery/default.jpg'
  ]

  constructor(config: NgbCarouselConfig) {
    config.interval = 20000;
    config.pauseOnHover = true;
  }

}
