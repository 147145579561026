import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AgreementComponent } from './agreement/agreement.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { AvgComponent } from './avg/avg.component';
import { PricesComponent } from './prices/prices.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openAgreement() {
    let dialogRef = this.dialog.open(AgreementComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openConditions() {
    let dialogRef = this.dialog.open(ConditionsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openPrices() {
    let dialogRef = this.dialog.open(PricesComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openPrivacy() {
    let dialogRef = this.dialog.open(PrivacyComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openAvg() {
    let dialogRef = this.dialog.open(AvgComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openComplaints() {
    let dialogRef = this.dialog.open(ComplaintsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
